"use strict";

async function importComponents() {
  const { default: React } = await import(
    /* webpackChunkName: "react" */ "react"
  );
  const { default: ReactDOM } = await import(
    /* webpackChunkName: "react" */ "react-dom"
  );
  const { default: DataSources } = await import(
    /* webpackChunkName: "sales" */ "./components/DataSources"
  );

  return { React, ReactDOM, DataSources };
}

importComponents().then(({ React, ReactDOM, DataSources }) => {
  const domContainer = document.querySelector("#data-sources");
  ReactDOM.render(
    React.createElement(DataSources, domContainer.dataset),
    domContainer
  );
});
